"use client";

import { useCallback, useState } from "react";
import { Button, Modal, Paper, Stack, Typography } from "@mui/material";
import Cookies from "js-cookie";

import { useIsMobile } from "common/util/hooks/useIsMobile";
import { useIsPrinting } from "common/util/hooks/useIsPrinting";

export const POLICY_ACCEPTED_COOKIE_ID = "policy_accepted";

export const AcceptableUsePolicyModal = () => {
  const isPrinting = useIsPrinting();
  const accepted = Cookies.get(POLICY_ACCEPTED_COOKIE_ID) === "true";
  const [open, setOpen] = useState<boolean>(!accepted);
  const [openAUP, setOpenAUP] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const acceptOnClick = useCallback(() => {
    Cookies.set(POLICY_ACCEPTED_COOKIE_ID, "true");
    setOpen(false);
  }, []);

  const toggleAupDetails = useCallback(() => {
    setOpenAUP((s) => !s);
  }, []);

  const AgreeButtonRow = useCallback(() => {
    return (
      <Stack direction="row" gap={1} flex={1}>
        <Button
          sx={{ textTransform: "capitalize" }}
          color="brand"
          variant="contained"
          onClick={acceptOnClick}
        >
          I Agree
        </Button>
        {isMobile && (
          <Button variant="text" onClick={toggleAupDetails}>
            <Typography variant="body2" sx={{ mt: "auto" }}>
              {openAUP ? "Hide" : "Read"} Acceptable Use Policy.
            </Typography>
          </Button>
        )}
      </Stack>
    );
  }, [acceptOnClick, isMobile, openAUP, toggleAupDetails]);

  return (
    <Modal open={open && !isPrinting} onClose={setOpen} className="web-only-no-pdf">
      <Paper
        elevation={10}
        className="web-only-no-pdf"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "max-content",
          maxWidth: "95vw",
          bgcolor: "background.paper",
          border: "0px transparent #000",
          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)"
        }}
      >
        <Stack gap={4} maxWidth="800px" p={4}>
          <Stack gap={1}>
            <Typography component="h1" variant="h1" color="brand.main">
              Acceptable Use Policy
            </Typography>
            <Typography variant="h5">
              By using this website you agree to our Acceptable Use Policy.
            </Typography>
          </Stack>
          {isMobile && <AgreeButtonRow />}
          <Stack
            sx={{
              maxHeight: {
                xs: "50vh",
                md: "100%"
              },
              overflowY: "scroll"
            }}
          >
            {(openAUP || !isMobile) && (
              <Stack gap={2}>
                <Typography variant="body1">
                  The Delaware Environmental Public Health Tracking Network (My Healthy Community)
                  strives to comply at all times with the rules governing the use or disclosure of
                  individual health information for research purposes. These rules include the
                  federal regulations under HIPAA for the protection of people&apos;s privacy
                  rights. Your use of these data must respect the privacy and confidentiality of
                  individuals, and as such, you must not attempt to identify any individuals whose
                  records were used in the calculation of the measures and results.
                </Typography>
                <Typography variant="body1">
                  As a user of this site, you hereby acknowledge that you will not make any attempt
                  to identify individual health records, and any attempt to do so constitutes a
                  violation of the terms of this agreement.
                </Typography>
              </Stack>
            )}
          </Stack>
          {!isMobile && <AgreeButtonRow />}
        </Stack>
      </Paper>
    </Modal>
  );
};
